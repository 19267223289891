<template>
  <b-card>
    <ValidationObserver>
      <form @submit.prevent="saveItem">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="name">Name:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formPayload.name"
                  placeholder="Category Name"
                />
              </validation-provider>
            </div>
            <!-- <div class="form-group">
              <label for="">Image / File:</label><br />
              <validation-provider name="image" rules="required">
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="changeFile($event)"
                  accept=".jpeg, .png, .jpg, .pdf"
                />
                <small>Current file:</small><br />
                <img
                  crossorigin="anonymous"
                  v-if="
                    (file_url !== null && file_type == 'image') ||
                    file_type == 'jpeg' ||
                    file_type == 'png' ||
                    file_type == 'jpg'
                  "
                  :src="file_url"
                  class="media_file"
                />
                <div
                  v-else-if="file_url !== null && file_type == 'pdf'"
                  class="d-flex align-items-center mt-1"
                >
                  <feather-icon icon="FileIcon" class="text-body" size="18" />
                  <a :href="file_url" download> Link download file </a>
                </div>
              </validation-provider>
            </div> -->
          </b-col>

          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="businessGroups"> Business Group: </label>
              <validation-provider name="businessGroup" rules="required">
                <v-select
                  id="businessGroups"
                  label="name_en"
                  v-model="selectedBusinessGroup"
                  :options="businessGroups"
                  placeholder="-- Pilih Business Group --"
                  :reduce="(businessGroups) => businessGroups.id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @keyup.stop.native="searchBusinessGroup"
                />
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <b-col cols="12" class="p-0 mt-2">
          <button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </button>
          &nbsp; &nbsp;
          <a
            @click="handleBackButton()"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Cancel
          </a>
        </b-col>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BFormFile,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import _ from "lodash";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormFile,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      required,
      isLoading: false,
      formPayload: {
        name: "",
        image_url: null,
        bussinessGroup: "",
      },
      validations: "",
      file_url: null,
      file_type: "",
      fileContent: "",
      businessGroups: [],
      selectedBusinessGroup: "",
      // Must be an array reference!
    };
  },
  created() {
    this.loadBusinessGroups();
  },
  methods: {
    handleBackButton() {
      history.back();
    },
    loadBusinessGroups(search) {
      this.$http
        .get("/admin/bussiness-group", {
          params: {
            search: search != null ? search : null,
          },
        })
        .then((response) => {
          this.businessGroups = response.data.data;
        });
    },
    searchBusinessGroup: _.debounce(function (e) {
      const search = e.target.value;
      this.loadBusinessGroups(search);
    }, 300),
    // changeFile(event) {
    //   this.formPayload.image_url = event.target.files[0];
    //   this.showFile(event.target.files[0]);
    // },
    // showFile(file) {
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     this.file_url = e.target.result;
    //     var str = e.target.result;
    //     var startIndex = str.indexOf("/") + 1;
    //     var endIndex = str.indexOf(";");
    //     var imageType = str.substring(startIndex, endIndex);
    //     this.file_type = imageType;
    //   };
    //   reader.readAsDataURL(file);
    // },
    saveItem() {
      this.isLoading = true;
      this.formPayload.bussinessGroup = this.selectedBusinessGroup;
      const payload = new FormData();
      payload.append("name", this.formPayload.name);
      payload.append("bussinessGroup", this.formPayload.bussinessGroup);
      // payload.append("image", this.formPayload.image_url);
      this.$http
        .post(`/admin/category`, payload)
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "Kategori sukses dibuat!");
          this.$router.push({ name: "category" });
          //   this.loadData();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta) {
            this.validations = error.response.data.meta.message;
            errorNotification(this, "Error", this.validations.join(", "));
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>
